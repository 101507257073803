import Heading from './comman/Heading';
import Paragraph from './comman/Paragraph';
import Stars from '../../assets/reviewPage/componentOne/stars.svg';
import bbbLogo from '../../assets/reviewPage/componentOne/bbbLogo.svg';
import trustpilotLogo from '../../assets/reviewPage/componentOne/trustpilotLogo.svg';
import Button from './comman/Button';
import CountUp from 'react-countup';

function ComponentOne() {
  return (
    <div className="flex flex-col gap-6 sm:gap-10 lg:gap-20 justify-center items-center w-full ">
      <Heading text1="Thousands Of" text2=" Homeowners " text3="Can't Be Wrong!" MaxWidth={true} />
      <Paragraph text1="For over a decade Nuborrow has been helping homeowners find lower rates and better mortgage options.  See why nuborrow is rated 4.8 with a 98% satisfaction ranking today!" />
      <div className="flex flex-col justify-center items-center gap-[14px] sm:gap-[22px] py-[22px] lg:py-8 px-8 lg:px-[22px] rounded-2xl drop-shadow-xl sm:drop-shadow-2xl bg-white">
        <p className="text-brand font-black text-2xl sm:text-4xl font-raleway text-center"><CountUp end={1500} decimals={0} suffix="+" enableScrollSpy /></p>
        <img src={Stars} alt="stars" />
        <p className="text-xs sm:text-lg font-medium font-raleway text-[#343434] text-center">
          4.8-star Google reviews from satisfied customer
        </p>
      </div>
      <a href='#getApproved'>
        <Button mode="Purple" text="Get Approved" id='page9_get_approved' /></a>
      <div className='flex items-center gap-4'>
        <img src={bbbLogo} alt="stars" />
        <img src={trustpilotLogo} alt="stars" />
      </div>
    </div>
  );
}

export default ComponentOne;
