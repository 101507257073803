interface Props {
  text1: String;
  MaxWidth?: boolean;
}
import { motion } from 'framer-motion';
import { textanimation } from '../../InteractivePart/Animation';
function Paragraph({ text1, MaxWidth }: Props) {
  return (
    <motion.div
      variants={textanimation}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
    >
      <p
        className={`font-sans text-[#737373] font-bold text-sm sm:text-base md:text-xl lg:text-2xl xl:text-[32px] text-center ${
          MaxWidth ? 'max-w-screen-xl' : 'max-w-screen-lg'
        }`}
      >
        {text1}
      </p>
    </motion.div>
  );
}
export default Paragraph;
