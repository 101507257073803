import Heading from './comman/Heading';
import Paragraph from './comman/Paragraph';
import { VideosData } from './constants/videos';
function ComopnentThree() {
  return (
    <div className="flex flex-col justify-center items-center w-full gap-9 md:gap-12">
      <Heading text1="Let's" text2=" get started " text3="on saving you money today!" />
      <Paragraph
        MaxWidth={true}
        text1='What a great company! We dealt with Mark, who was so personable and quick to assist us with our renewal. We have been dealing with Nuborrow for a few years and appreciate the transparency and advice as we navigate through the process!'
      />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 md:gap-7 w-full max-w-screen-xl">
        {VideosData.map((data) => {
          return (<div className="relative min-h-52 sm:min-h-80">
            <iframe width="100%" height="100%" className='rounded-xl' src={data.link} title="Noborrow Review Video" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
          </div>)
        })}

      </div>
    </div>
  );
}

export default ComopnentThree;
