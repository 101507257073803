import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import ComponentOne from "../components/ReviewPage/ComponentOne";
import ComponentTwo from "../components/ReviewPage/ComponentTwo";
import ComopnentThree from "../components/ReviewPage/ComponentThree";
import ComponentFour from "../components/ReviewPage/componentFour/index"
import { useEffect } from "react";
function ReviewPage() {
    useEffect(() => { window.scrollTo({ top: 0, }) }, [])
    return (
        <main>
            <Header className="mx-auto" review_page={true} />

            <div className="flex flex-col justify-center items-center w-full py-10 md:py-20 container mx-auto px-4">
                <ComponentOne />
            </div>
            <div className="w-full bg-white">
                <div className="flex flex-col gap-10 md:gap-20 justify-center items-center w-full  pt-10 md:pt-20  container mx-auto px-4 ">
                    <ComponentTwo />
                    <ComopnentThree />
                </div>
                <div id="getApproved" className="flex w-full container mx-auto px-4 ">
                    <ComponentFour reviewPage={true} />
                </div>
            </div>

            <Footer className="mx-auto" />
        </main>
    );
}

export default ReviewPage;