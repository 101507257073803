import React, { useState } from 'react';
import RadioButton from './RadioComponent/index';
import { motion } from 'framer-motion';
import { textanimation } from './Animation/index';
import backButton from "../../../assets/back.svg"
import BackButton from './Button/BackButton';


type Props = {
  setopenComponent: React.Dispatch<React.SetStateAction<number>>;
  setCreditScore: React.Dispatch<React.SetStateAction<string>>;
  isReviewPage:boolean
};

const FivePageCompoFive = ({ setopenComponent, setCreditScore, isReviewPage }: Props) => {
  const [check, setCheck] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [check3, setCheck3] = useState(false);
  const [check4, setCheck4] = useState(false);
  const [errorbox, setErrorbox] = useState('');
  // console.log('*********', check, '********');
  // **********
  // const checkValidity = (check: Boolean) => {
  //   // check ? setopenComponent(3) : setErrorbox('Select answer first');
  //   check ? setopenComponent(6) : setErrorbox('Select answer first');
  // };
  // **********
  let path = 6;

  return (
    <div className='pt-10'>
      <BackButton  setopenComponent = {setopenComponent} id = {4}/>

      <div className="bg-mainCard">
        <div className="pt-10">
          <motion.div variants={textanimation} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
            <p className="font-raleway mx-2 md:px-36 sm:mx-0 text-3xl md:text-4xl lg:text-5xl xl:text-7xl text-center font-black  ">
              We've added over 50,000 points to clients credit scores.
            </p>
          </motion.div>
          <motion.div variants={textanimation} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
            <p className="font-sans font-bold mx-2 md:px-36 sm:mx-0 text-sm sm:text-base md:text-xl lg:text-2xl xl:text-3xl text-center text-[#737373] mt-10">
              Do you know current credit score? (it's OK if you don't, we'll give you your
              free score in your report).
            </p>
          </motion.div>
        </div>
        <div className="flex flex-col justify-center items-center mt-10">
          <RadioButton
            setopenComponent={setopenComponent}
            path={path}
            setCheck={setCheck}
            setCheck2={setCheck2}
            setCheck3={setCheck3}
            setCheck4={setCheck4}
            setGoal={setCreditScore}
            check={check}
            id={isReviewPage?"page9_btn_very_good":"page6_btn_very_good"}
            content={'Very Good (720 +)'}
          />
          <RadioButton
            path={path}
            setopenComponent={setopenComponent}
            setCheck={setCheck}
            setCheck2={setCheck2}
            setCheck3={setCheck3}
            setCheck4={setCheck4}
            setGoal={setCreditScore}
            check={check2}
            id={isReviewPage?"page9_btn_good":"page6_btn_good"}
            content={'Good (650 - 719)'}
          />
          <RadioButton
            path={path}
            setopenComponent={setopenComponent}
            setCheck={setCheck}
            setCheck2={setCheck2}
            setCheck3={setCheck3}
            setCheck4={setCheck4}
            setGoal={setCreditScore}
            check={check3}
            id={isReviewPage?"page9_btn_average":"page6_btn_average"}
            content={'Average  (600 - 649)'}
          />
          <RadioButton
            path={path}
            setopenComponent={setopenComponent}
            setCheck={setCheck}
            setCheck2={setCheck2}
            setCheck3={setCheck3}
            setCheck4={setCheck4}
            setGoal={setCreditScore}
            check={check4}
            id={isReviewPage?"page9_btn_help":"page6_btn_help"}
            content={'I need help (< 599)'}
          />
          <div className="mt-5 mb-20 flex flex-col">
            {/* <button
              onClick={() =>
                checkValidity(check ? true : check2 ? true : check3 ? true : check4 ? true : false)
              }
              // onClick={() => setopenComponent(6)}
              className="bg-brand rounded-full font-sans text-lg	font-bold text-white px-12  py-3  sm:px-32 sm:py-4 mt-8  hover:bg-white hover:text-brand hover:border-brand hover:border-2"
            >
              Next
            </button> */}
            <span className="text-red-700 mx-auto text-center sm:w-[65%] my-4 text-[10px] sm:text-[12px] font-semibold">
              {errorbox}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FivePageCompoFive;
