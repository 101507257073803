import Heading from './comman/Heading';
import ReviewStars from '../../assets/reviewPage/componentTwo/reviewStars.svg';
import { ReviewsData } from './constants/reviews';
import '../../styles/global.css';
import Button from './comman/Button';
import { useState } from 'react';
function ComponentTwo() {
  const [Slice, setSlice] = useState(6);
  return (
    <div className="flex flex-col justify-center items-center gap-3 md:gap-12 w-full">
      <div className="flex flex-col justify-center items-center gap-3 md:gap-[30px] w-full">
        <Heading
          text2="Nuborrow"
          text3=" reviews The "
          text4="(almost)"
          text5=" perfect "
          text6="5-star"
          text7="brokerage."
          lineHeight={true}
        />
        <div
          id="reviewsGrid"
          className="grid max-md:max-h-96  max-md:rounded-xl  max-md:px-3 max-md:py-4 grid-cols-1 md:grid-cols-2 lg:grid-rows-2 lg:grid-cols-3 gap-y-[30px] gap-x-12 w-full max-md:overflow-y-scroll scroll-smooth snap-y snap-mandatory max-md:shadow-[inset_0_0_15px_2px_#CDCDCD] "
        >
          {ReviewsData.slice(0, Slice).map((data, index) => {
            return (
              <div className="flex flex-col gap-4 md:gap-6 justify-center items-start px-[22px] md:px-7 pt-[22px] md:pt-7 pb-3 rounded-lg shadow-xl border-[1px] text-[#343434] snap-always max-md:hidden">
                <div className="flex justify-between items-start w-full">
                  {' '}
                  <div className="flex gap-4 justify-center items-center">
                    <img src={data.image} alt={data.name} className="rounded-full w-10 h-10" />
                    <div>
                      <p className="max-md:text-xs font-bold">{data.name}</p>
                      <p className="text-xs">{data.date}</p>
                      {/* <img src={data.reviewStar} alt="review stars" /> */}
                    </div>
                  </div>
                  <img src={data.logo} alt="review stars" />
                </div>
                <img src={data.reviewStar} alt="review stars" />
                <p className="text-[10px] md:text-xs">{data.description}</p>
              </div>
            );
          })}

          {ReviewsData.map((data) => {
            return (
              <div className="flex flex-col gap-4 md:gap-6 justify-center items-start px-[22px] md:px-7 pt-[22px] md:pt-7 pb-3 rounded-lg shadow-xl  border-[1px] text-[#343434] snap-always md:hidden">
                <div className="flex gap-4 justify-center items-center">
                  <img src={data.image} alt={data.name} className="rounded-full w-8 h-8" />
                  <div>
                    <p className="max-md:text-xs font-bold">{data.name}</p>
                    <img src={ReviewStars} alt="review stars" />
                  </div>
                </div>
                <p className="text-[10px] md:text-xs">{data.description}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div
        onClick={() => {
          if (Slice >= ReviewsData.length) {
            return;
          }
          setSlice(Slice + 3);
        }}
        className="max-md:hidden"
      >
        <Button mode={Slice >= ReviewsData.length ? 'Disable' : 'Grey'} text="View more" />
      </div>
    </div>
  );
}

export default ComponentTwo;
