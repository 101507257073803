interface Props{
    text1?:String,
    text2?:String,
    text3?:String,
    text4?:String,
    text5?:String,
    text6?:String,
    text7?:String,
    lineHeight?:boolean,
    MaxWidth?:boolean
}
import { motion } from 'framer-motion';
import { textanimation } from '../../InteractivePart/Animation';

function Heading({text1,text2,text3,text4,text5,text6,text7,lineHeight=false, MaxWidth=false}:Props){

    return (
     <motion.div
        variants={textanimation}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true }}
      >
    <p className={`font-raleway mx-2 sm:mx-0 text-3xl  md:text-4xl lg:text-5xl xl:text-7xl text-center font-black  ${MaxWidth?" max-w-screen-lg ":" max-w-screen-xl "} ${lineHeight && "lg:leading-[85px]"}`} >
    {text1}  
    <span className='text-brand'>{text2}</span>  {text3 }
    <span className='text-brand'>{text4}</span>  {text5 }
    <span className='text-brand'>{text6}</span>  {text7 }
    </p>
    </motion.div>);

}
export default Heading;